import {
  faArchway,
  faCalendarDay,
  faGlassCheers,
} from "@fortawesome/pro-duotone-svg-icons"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import bloodyMaryContestAboutStyle from "assets/jss/material-kit-pro-react/views/bloodyMaryContestPageSections/bloodyMaryContestAboutStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(bloodyMaryContestAboutStyle)

export default function BloodyMaryContestAbout() {
  const { bloodyMaryContestAboutImage1 } = useStaticQuery(graphql`
    query bloodyMaryContestAboutImages {
      bloodyMaryContestAboutImage1: file(
        relativePath: { eq: "bloody-mary-contest/bloody-mary-contest-3.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>
              Who makes the area’s Best Bloody Mary?
            </h2>
          </GridItem>
        </GridContainer>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={12} md={5} className={classes.mlAuto}>
            <GatsbyImage
              image={
                bloodyMaryContestAboutImage1.childImageSharp.gatsbyImageData
              }
              className={classes.bloodyMaryContestAboutImage}
              objectPosition={"bottom"}
              alt="Bloody Mary Picture"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={7} className={classes.mrAuto}>
            <InfoArea
              className={classes.info4}
              title="Where"
              description="Two Brothers Pub &amp; Grill, 116 W. Fulton Street in downtown Edgerton"
              faIcon={faArchway}
              iconColor="primary"
            />
            <InfoArea
              className={classes.info4}
              title="When"
              description="10AM"
              faIcon={faCalendarDay}
              iconColor="secondary"
            />
            <InfoArea
              className={classes.info4}
              title="Why"
              description="Come and enjoy the best Bloody Marys the Edgerton area has to offer, and you could be chosen to help decide the winners! Admission is FREE … but you must be age 21+ to register to be a judge. Judges will be chosen that morning using a lottery system."
              faIcon={faGlassCheers}
              iconColor="primary"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
