// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import bloodyMaryContestPageStyle from "assets/jss/material-kit-pro-react/views/bloodyMaryContestPageStyle.jsx"
// core components
import CustomFooter from "components/Footer/CustomFooter.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomHeader from "components/Header/CustomHeader.jsx"
import ReactParallax from "components/Parallax/Parallax.jsx"
import SEOHeader from "components/SEOHeader/SEOHeader.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SectionTimer from "../LandingPage/Sections/SectionTimer.jsx"
// sections for this page
import BloodyMaryContestAbout from "./Sections/BloodyMaryContestAbout.jsx"
import BloodyMaryContestInformation from "./Sections/BloodyMaryContestInformation.jsx"

const useStyles = makeStyles(bloodyMaryContestPageStyle)

export default function BloodyMaryContestPage() {
  const { bloodyMaryContestBackground } = useStaticQuery(graphql`
    query getBloodyMaryContestPageImages {
      bloodyMaryContestBackground: file(
        relativePath: { eq: "banners/bloody-mary-contest.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div>
      <SEOHeader
        title="Bloody Mary Contest - Chilimania"
        description="Information and rules about competing in the Chilimania Bloody Mary Contest"
      />
      <CustomHeader />
      <ReactParallax
        imageData={bloodyMaryContestBackground.childImageSharp.gatsbyImageData}
        imageTitle={"Bloody Mary Contest Banner"}
        filter="dark"
        imageStyle={{ height: "75vh" }}
        objectPosition={"bottom"}
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem md={8} sm={8} className={classes.scholarshipsItem}>
              <h1 className={classes.pageTitle}>Bloody Mary Contest</h1>
              <h3 className={classes.message}>
                Located at 2 Brother's Bar &amp; Grill
              </h3>
            </GridItem>
          </GridContainer>
        </div>
      </ReactParallax>
      <div className={classes.main}>
        <BloodyMaryContestAbout />
        <BloodyMaryContestInformation />
        <SectionTimer />
        <CustomFooter />
      </div>
    </div>
  )
}
